import { ApolloError, ServerError } from "@apollo/client"
import {
  MutationUpdateSubmittedArgs,
  MutationUpdateFirmArgs,
} from "graphql-types/generated/portal-client-types"
import * as Sentry from "@sentry/react"

export function isServerError(error: any): error is ServerError {
  return (
    error &&
    error.hasOwnProperty("statusCode") &&
    error.hasOwnProperty("result") &&
    error.hasOwnProperty("response")
  )
}

interface ServerErrorResponse {
  message: string
  extensions: {
    code: string
  }
}

export function isUnauthenticated(error: ServerError) {
  const code = error.result.errors.find(
    (err: ServerErrorResponse) => err.extensions.code === "UNAUTHENTICATED",
  )
  return !!code
}

export function reportErrorWithFormData(
  error: ApolloError,
  formData: MutationUpdateFirmArgs | MutationUpdateSubmittedArgs,
) {
  Sentry.withScope((scope) => {
    scope.addBreadcrumb({
      type: "debug",
      category: "formData",
      data: formData,
    })
    Sentry.captureException(error)
  })
}

export function returnHumanReadableError(
  apolloError: ApolloError,
  defaultMessage = "Error submitting form data",
) {
  if (apolloError.message.includes("duplicative")) {
    return apolloError.message
  }
  return defaultMessage
}
