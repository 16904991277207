import { Container, Typography } from "@material-ui/core"
import Form from "../CustomForm"
import * as Sentry from "@sentry/react"
import { JSONSchema7Object } from "json-schema"
import React, { useContext, useState, useMemo, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import * as types from "graphql-types/generated/portal-client-types"
import { Button } from "../Button"
import Paper from "../Paper"
import { Error } from "../../Toast"
import { AuthContext } from "../../context/AuthContext"
import { CURRENT_USER, UPDATE_USER } from "./graphql"
import ArrayFieldTemplate from "../ArrayFieldTemplate"
import { get } from "lodash"
import { default as schemaJson } from "../../data/user-profile-schema.json"
import { default as uiSchema } from "../../data/user-profile-ui-schema.json"
import ErrorListTemplate from "../form/ErrorListTemplate"
import Spinner from "../Spinner"
import { toast } from "react-toastify"
import { returnHumanReadableError } from "../../utils/errorHandler"

const schema: JSONSchema7Object = schemaJson

export const Profile = () => {
  const history = useHistory()

  const [{ isAdmin, isROAdmin, userId }] = useContext(AuthContext)
  const { data, loading, error: currentUserError } = useQuery(CURRENT_USER, {
    variables: { id: userId },
    fetchPolicy: "cache-and-network",
  })
  const currentUser = useMemo(() => {
    return get(data, "currentUser")
  }, [data])
  const [formData, setFormData] = useState<types.User | undefined>(currentUser)

  const [updateUser, { error }] = useMutation(UPDATE_USER)

  useEffect(() => {
    if (currentUser) {
      setFormData(currentUser)
    }
  }, [currentUser, data, loading])

  const handleSubmit = async (event: any): Promise<void> => {
    if (!currentUser) return
    const variables: types.MutationUpdateUserArgs = {
      id: currentUser.id,
      input: {
        firstName: event.formData.firstName,
        lastName: event.formData.lastName,
        phoneNumber: event.formData.phoneNumber,
        streetAddress1: event.formData.streetAddress1,
        streetAddress2: event.formData.streetAddress2,
        city: event.formData.city,
        state: event.formData.state,
        zipCode: event.formData.zipCode,
        country: event.formData.country,
      },
    }
    await updateUser({ variables })
    setFormData(event.formData)
    history.push("/")
    if (error) {
      toast(<Error body={returnHumanReadableError(error)} />)
    }
  }

  if (currentUserError) {
    Sentry.withScope((scope) => {
      scope.addBreadcrumb({
        category: "stack",
        message: currentUserError.stack,
      })
      scope.addBreadcrumb({
        category: "extraInfo",
        message: currentUserError.extraInfo,
      })
      scope.addBreadcrumb({
        category: "errorName",
        message: currentUserError.name,
      })
      scope.setExtra("networkError", currentUserError.networkError)
      Sentry.captureException(currentUserError)
    })
    toast(<Error body='Error fetching user profile' />)
  }

  if (loading) {
    return (
      <div className='profile'>
        <Paper>
          <Spinner />
        </Paper>
      </div>
    )
  }

  return (
    <div className='profile'>
      <Paper>
        <Typography variant='h5' component='h5' gutterBottom>
          Profile
        </Typography>
        <Container>
          <Form
            ArrayFieldTemplate={ArrayFieldTemplate}
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={handleSubmit}
            formData={formData}
            noValidate={true}
            disabled={isROAdmin}
            noHtml5Validate={true}
            ErrorList={ErrorListTemplate}
          >
            <Button
              variant='contained'
              color='primary'
              type='submit'
              {...((isAdmin || isROAdmin) && {
                disabled: true,
              })}
            >
              Submit
            </Button>
          </Form>
        </Container>
      </Paper>
    </div>
  )
}

export default Profile
