import Box from "@material-ui/core/Box"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import Alert from "@material-ui/lab/Alert"
import * as types from "graphql-types/generated/portal-client-types"
import { get } from "lodash"
import React, { useContext, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { v4 as uuid } from "uuid"
import { Button } from "../components/Button"
import { ConfirmModal } from "../components/ConfirmModal"
import { AuthContext } from "../context/AuthContext"

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  noFunds: {
    justifyContent: "center",
  },
  button: {
    margin: 0,
  },
  deleteButton: {
    minWidth: "100px",
  },
  indented: {
    marginRight: 30,
  },
})

interface Props {
  firm: types.Firm
  indent: boolean
  onDeleteFirm(firm: types.Firm): Promise<void>
  onDeleteSubmission(submission: types.Submitted): Promise<void>
}

export const BoardRow = ({
  firm,
  indent,
  onDeleteFirm,
  onDeleteSubmission,
}: Props): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false)
  const newFirmId = useMemo(() => uuid(), [])
  const [authContextState] = useContext(AuthContext)
  const classes = useRowStyles()
  const history = useHistory()

  function viewFirmProfile() {
    history.push(`/firm/${firm.id}`)
  }

  function deleteFirm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setShowDeleteConfirm(true)
    event.stopPropagation()
  }

  function addFirm(): void {
    history.push(
      `/firm/${newFirmId}?new=true&organizationId=${firm.organizationId}&firmType=${firm.firmType?.name}`,
    )
  }

  function cancelDeleteFirm() {
    setShowDeleteConfirm(false)
  }

  async function deleteFirmConfirmed() {
    await onDeleteFirm(firm)
    setShowDeleteConfirm(false)
  }

  function viewForm(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    formId: string,
  ) {
    history.push(`/form/${formId}`)
  }

  function addForm() {
    history.push(`/fill-form/${firm.id}`)
  }

  const submissionData = useMemo(() => {
    return get(firm, "submissionList.items", [])
  }, [firm])

  const iconButton = (
    <IconButton
      data-cy='expand-board-row'
      aria-label='expand row'
      size='small'
      className={indent ? classes.indented : undefined}
      onClick={() => setOpen(!open)}
    >
      {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
    </IconButton>
  )
  return (
    <>
      <TableRow
        data-cy='board-row'
        className={`board-row ${classes.root}`}
        hover={true}
        onClick={() => setOpen(!open)}
      >
        <TableCell>{indent ? "" : iconButton}</TableCell>
        <TableCell component='th' scope='row'>
          {indent ? iconButton : ""}
          <Typography
            data-cy='firm-name'
            variant='h6'
            gutterBottom
            component='span'
          >
            {get(firm, "name", "")}
          </Typography>
        </TableCell>
        <TableCell align='left'>{get(firm, "url", "")}</TableCell>
        <TableCell align='left'>
          <Button
            className={classes.button}
            data-cy='view-firm-profile'
            variant='outlined'
            color='primary'
            onClick={viewFirmProfile}
          >
            View Profile
          </Button>
        </TableCell>
        {authContextState.isAdmin === true && (
          <TableCell align='right'>
            <Button
              className={classes.deleteButton}
              data-cy='view-firm-profile'
              variant='outlined'
              color='secondary'
              onClick={deleteFirm}
            >
              Delete Firm
            </Button>

            <ConfirmModal
              name={get(firm, "name", "") as string}
              onCancel={cancelDeleteFirm}
              onConfirm={deleteFirmConfirmed}
              open={showDeleteConfirm}
            />
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={open}
            timeout='auto'
            unmountOnExit
            style={{ paddingBottom: 24 }}
          >
            <Box margin={1}>
              <BoardRowContent
                allowMultiFirm={firm.organization?.allowMultiFirm}
                submissions={submissionData}
                isAdmin={authContextState.isAdmin}
                isROAdmin={authContextState.isROAdmin}
                addFirm={addFirm}
                viewForm={viewForm}
                addForm={addForm}
                onDeleteSubmission={onDeleteSubmission}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

interface BoardRowContentProps {
  submissions: types.Submitted[]
  allowMultiFirm?: boolean | null
  isAdmin: boolean
  isROAdmin: boolean
  addFirm: () => void
  viewForm: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    formId: string,
  ) => void
  addForm: () => void
  onDeleteSubmission(submission: types.Submitted): Promise<void>
}

function BoardRowContent({
  submissions,
  allowMultiFirm,
  isAdmin,
  isROAdmin,
  addFirm,
  viewForm,
  addForm,
  onDeleteSubmission,
}: BoardRowContentProps) {
  const classes = useRowStyles()
  const [deletingSubmissionId, setDeletingSubmissionId] = React.useState("")

  function deleteSubmission(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    submission: types.Submitted,
  ) {
    setDeletingSubmissionId(submission.id)
    event.stopPropagation()
  }

  function cancelDeleteSubmission() {
    setDeletingSubmissionId("")
  }

  async function deleteSubmissionConfirmed(submission: types.Submitted) {
    await onDeleteSubmission(submission)
    setDeletingSubmissionId("")
  }

  return (
    <Table size='small' aria-label='purchases' data-cy='submission-rows'>
      {submissions.length > 0 && (
        <TableHead>
          <TableRow>
            <TableCell>Investment Name</TableCell>
            <TableCell>Asset Class</TableCell>
            <TableCell align='right'>Submitted By</TableCell>
            {isAdmin === true && <TableCell />}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {submissions.map((form) => {
          return (
            <TableRow key={form.id} className={classes.root} hover={true}>
              <TableCell component='th' scope='row'>
                <Button
                  className={classes.button}
                  data-cy='submission-name-button'
                  variant='outlined'
                  color='primary'
                  onClick={(e) => viewForm(e, form.id)}
                  name='form'
                >
                  {get(form, "name", "")}
                </Button>
              </TableCell>
              <TableCell component='th' scope='row'>
                <Typography data-cy='submission-source-name' component='h6'>
                  {get(form, "source.name", "")}
                </Typography>
              </TableCell>
              <TableCell align='right'>{get(form, "email", "")}</TableCell>
              {isAdmin === true && (
                <TableCell component='th' scope='row' align='right'>
                  <Button
                    className={classes.deleteButton}
                    data-cy='view-firm-profile'
                    variant='outlined'
                    onClick={(event) => deleteSubmission(event, form)}
                  >
                    Delete Investment
                  </Button>

                  <ConfirmModal
                    name={get(form, "name", "") as string}
                    onCancel={cancelDeleteSubmission}
                    onConfirm={() => deleteSubmissionConfirmed(form)}
                    open={form.id === deletingSubmissionId}
                  />
                </TableCell>
              )}
            </TableRow>
          )
        })}
        {submissions.length === 0 && (
          <TableRow>
            <TableCell component='th' scope='row' colSpan={isAdmin ? 4 : 3}>
              <Alert severity='info' icon={null} className={classes.noFunds}>
                No investments (yet)
              </Alert>
            </TableCell>
          </TableRow>
        )}
        {!isROAdmin && (
          <TableRow hover={true}>
            <TableCell component='th' scope='row' colSpan={isAdmin ? 4 : 3}>
              <Button
                className={classes.button}
                variant='outlined'
                data-cy='add-submission'
                color='primary'
                onClick={addForm}
                name='form'
                fullWidth
              >
                Add Investment
              </Button>
            </TableCell>
          </TableRow>
        )}
        {allowMultiFirm && !isROAdmin && (
          <TableRow hover={true}>
            <TableCell component='th' scope='row' colSpan={isAdmin ? 4 : 3}>
              <Button
                className={classes.button}
                variant='outlined'
                data-cy='add-secondary-firm'
                color='primary'
                onClick={addFirm}
                name='form'
                fullWidth
              >
                Add Additional Firm to this Organization
              </Button>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export default BoardRow
