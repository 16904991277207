import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined"
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import React from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./toast.css"

export const alertColors = {
  info: "#1890ff",
  success: "#52c41a",
  warning: "#faad14",
  error: "#ff4d4f",
}

export const Info = ({ body }: { body: string }) => (
  <div className='alert-body'>
    <InfoOutlinedIcon
      style={{ color: alertColors.info, fontSize: "24px", marginLeft: "4px" }}
    />
    <p style={{ marginLeft: "28px" }}>{body}</p>
  </div>
)
export const Error = ({ body }: { body: string }) => (
  <div className='alert-body'>
    <ErrorOutlineOutlinedIcon style={{ color: alertColors.error }} />
    <p>{body}</p>
  </div>
)
export const Success = ({ body }: { body: string }) => (
  <div className='alert-body'>
    <CheckCircleOutlineOutlinedIcon style={{ color: alertColors.success }} />
    <p>{body}</p>
  </div>
)
export const Warning = ({ body }: { body: string }) => (
  <div className='alert-body'>
    <ErrorOutlineOutlinedIcon style={{ color: alertColors.warning }} />
    <p>{body}</p>
  </div>
)

function ToastAlert() {
  return (
    <ToastContainer
      role='alert'
      position='top-right'
      autoClose={2500}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      limit={3}
    />
  )
}

export default ToastAlert
