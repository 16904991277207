import { useMutation, useQuery } from "@apollo/client"
import { Typography } from "@material-ui/core"
import * as types from "graphql-types/generated/portal-client-types"
import { JSONSchema7Object } from "json-schema"
import { isEmpty } from "lodash"
import React, { useContext, useEffect, useMemo, useState } from "react"
import ReactGA from "react-ga"
import { Redirect, useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { AuthContext } from "../../context/AuthContext"
import investmentCreationSchema from "../../data/investment-creation-schema.json"
import investmentCreationUISchema from "../../data/investment-creation-ui-schema.json"
import { Error } from "../../Toast"
import { returnHumanReadableError } from "../../utils/errorHandler"
import { Button } from "../Button"
import Form from "../CustomForm"
import ErrorListTemplate from "../form/ErrorListTemplate"
import Spinner from "../Spinner"
import { GET_ASSET_CLASSES, INSERT_SUBMISSION } from "./graphql"

interface SchemaType {
  properties: {
    name: {
      type: string
      title: string
    }
    source: {
      type: string
      title: string
      enum?: string[]
    }
  }
}

const AssetSelection = () => {
  const [authContextState] = useContext(AuthContext)
  const [schema, setSchema] = useState<JSONSchema7Object>(
    investmentCreationSchema,
  )
  const [uiSchema] = useState<JSONSchema7Object>(investmentCreationUISchema)
  const history = useHistory()
  const { firmId } = useParams<{ firmId: string }>()
  const { data: assetClassData, loading } = useQuery<{
    assetClassEnumList: types.AssetClassListQuery["assetClassEnumList"]
  }>(GET_ASSET_CLASSES)
  const [
    createInitialSubmission,
    { data, loading: submittedLoading, error },
  ] = useMutation(INSERT_SUBMISSION)

  const assetClasses: types.AssetClassEnum[] = useMemo(() => {
    return assetClassData?.assetClassEnumList ?? []
  }, [assetClassData, loading])

  useEffect(() => {
    const mappedClasses = assetClasses.map((assetClass) => assetClass.name)
    const typedSchema = (schema as unknown) as SchemaType
    setSchema({
      ...typedSchema,
      properties: {
        ...typedSchema.properties,
        source: { ...typedSchema.properties.source, enum: mappedClasses },
      },
    })
  }, [assetClasses])

  async function handleSubmit(e: any) {
    if (authContextState.isROAdmin) return
    const { name, source } = e.formData
    const sourceId = assetClasses.find((a) => a.name === source)?.id
    await createInitialSubmission({
      variables: {
        input: {
          sourceId,
          firmId,
          name,
        },
      },
    })
    ReactGA.event({
      category: "AssetForm",
      action: "AssetForm created",
    })
  }

  useEffect(() => {
    if (!submittedLoading && !isEmpty(data)) {
      history.push(`/form/${data.insertSubmitted.id}`)
    }
  }, [submittedLoading, data])

  if (loading || submittedLoading) {
    return (
      <div className='asset-selection'>
        <Spinner />
      </div>
    )
  }

  if (!firmId) {
    return <Redirect to='/' />
  }

  if (error) {
    toast(<Error body={returnHumanReadableError(error)} />)
    history.push("/")
  }

  return (
    <div className='asset-selection'>
      <Typography variant='h5' component='h6' gutterBottom>
        Select an Asset Class
      </Typography>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={handleSubmit}
        ErrorList={ErrorListTemplate}
      >
        <Button variant='contained' color='primary' type='submit'>
          Submit
        </Button>
      </Form>
    </div>
  )
}

export default AssetSelection
