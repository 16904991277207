import { useMutation } from "@apollo/client"
import { Firm, User } from "graphql-types/generated/portal-client-types"
import { get } from "lodash"
import React, { createContext, useContext, useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { Error, Warning } from "../Toast"
import { isServerError, isUnauthenticated } from "../utils/errorHandler"
import { AuthContext } from "./AuthContext"
import { SYNC_DB_WITH_COGNITO } from "./graphql"
import { getRegisteredFirmsFromOrganization } from "./helpers"

export type TState = {
  currentUser?: User
  isValidUser: boolean
  registeredFirms?: Firm[]
  isLoading: boolean
}

const defaultState: TState = {
  currentUser: undefined,
  isValidUser: false,
  registeredFirms: [],
  isLoading: true,
}

const MainContext = createContext<TState>(defaultState)

const MainProvider: React.FC = ({ children }) => {
  const [authContext, { signOut }] = useContext(AuthContext)
  const history = useHistory()
  const [
    syncDBWithCognito,
    { data: currentUserData, loading, called, error },
  ] = useMutation(SYNC_DB_WITH_COGNITO)

  const registeredUser = useMemo(() => {
    return get(authContext, "registrationProps.user", {})
  }, [authContext])
  const registeredOrg = useMemo(() => {
    return get(authContext, "registrationProps.organization", {})
  }, [authContext])

  useEffect(() => {
    if (!authContext.isLoading && authContext.userId) {
      const { email, ...restOfUser } = registeredUser
      syncDBWithCognito({
        variables: {
          user: restOfUser,
          organization: registeredOrg,
        },
      })
    }
  }, [
    authContext.email,
    authContext.isLoading,
    authContext.userId,
    registeredUser,
    registeredOrg,
    syncDBWithCognito,
  ])

  if (error) {
    if (
      error.networkError &&
      isServerError(error.networkError) &&
      isUnauthenticated(error.networkError)
    ) {
      signOut()
      history.push("/login")
    }
    toast(<Error body='Something went wrong, please refresh and try again.' />)
  }

  const currentUser: User = useMemo(() => {
    return get(currentUserData, "syncDBWithCognitoUser")
  }, [currentUserData])

  const context = useMemo(() => {
    const organization = get(currentUser, "organization")
    return {
      currentUser,
      isValidUser: !!(
        !error &&
        currentUser &&
        currentUser.id &&
        organization?.id &&
        (!!organization?.primaryFirm ||
          organization.serviceProviderFirms.length > 0)
      ),
      registeredFirms: getRegisteredFirmsFromOrganization(
        currentUser && currentUser.organization,
      ),
      isLoading: !called || loading,
    }
  }, [authContext, currentUser, loading, called])

  useEffect(() => {
    if (currentUser && !currentUser.organizationId) {
      toast(
        <Warning
          body={`The email you provided "${currentUser.email}" does not appear to represent an investment company.   In order to use this email, an ODC associate will need to setup your account.   We will email you with further information`}
        />,
      )
    }
  }, [currentUser])

  return <MainContext.Provider value={context}>{children}</MainContext.Provider>
}

export { MainContext, MainProvider }
