import { TableCell, TableRow } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import * as types from "graphql-types/generated/portal-client-types"
import React from "react"
import Paper from "../Paper"
import { UserTable } from "../UserTable"

interface SubmissionRowProps {
  allowMultiFirm?: boolean
  isPrimaryFirm?: boolean
  firm?: types.Firm | null
  noSubmissionsMessage?: string
  handleClick(
    event: React.MouseEvent,
    form: types.Submitted,
    isEditable: boolean,
  ): void
}

function SubmissionRows({
  firm,
  allowMultiFirm = false,
  isPrimaryFirm = true,
  handleClick,
  noSubmissionsMessage,
}: SubmissionRowProps) {
  const submissions = firm?.submissionList?.items ?? []

  if (!firm || !submissions || submissions.length < 1) {
    if (!noSubmissionsMessage) return null

    return <Alert severity='info'>{noSubmissionsMessage}</Alert>
  }

  const colName = isPrimaryFirm && !allowMultiFirm ? "Firm" : firm?.name ?? ""

  return (
    <Paper>
      <UserTable data-id={`firm__${firm.id}`} col0={colName}>
        {submissions.map((form) => {
          return (
            <TableRow
              data-cy='submission-row'
              key={form.id}
              onClick={(e) => handleClick(e, form, true)}
              className='table-row'
            >
              <TableCell />

              <TableCell data-cy='submission-source' component='th' scope='row'>
                {form.source?.name}
              </TableCell>
              <TableCell
                data-cy='submission-name'
                component='th'
                scope='row'
                align='left'
              >
                <>{form.name}</>
              </TableCell>
              <TableCell component='th' scope='row' align='right'>
                {form.email}
              </TableCell>
            </TableRow>
          )
        })}
      </UserTable>
    </Paper>
  )
}

export default SubmissionRows
