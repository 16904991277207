import { useMutation, useQuery } from "@apollo/client"
import { Container, Paper } from "@material-ui/core"
import { ErrorSchema, IChangeEvent, ISubmitEvent, UiSchema } from "@rjsf/core"
import * as types from "graphql-types/generated/portal-client-types"
import { JSONSchema7 } from "json-schema"
import { get } from "lodash"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { AuthContext } from "../../context/AuthContext"
import { Error, Success } from "../../Toast"
import { getSchemaResponse } from "../../utils/buildSchema"
import {
  reportErrorWithFormData,
  returnHumanReadableError,
} from "../../utils/errorHandler"
import FormTemplate from "../../utils/FormTemplate"
import Form from "./Form"
import { GET_FORM, UPDATE_SUBMITTED } from "./graphql"
import ImportUpdates from "./ImportUpdates"

function AssetForm() {
  const { formId } = useParams<{ formId: string }>()
  const history = useHistory()
  const [schema, setSchema] = useState<JSONSchema7 | any>(null)
  const [uiSchema, setUISchema] = useState<UiSchema>({})
  const [labels, changeLabels] = useState<any>({})
  const [skipUpdatePrompt, changeSkipUpdatePrompt] = useState<number>(0)
  const [formData, setFormData] = useState<any>(null)
  const [authContextState] = useContext(AuthContext)
  const [pendingUpdates, changePendingUpdates] = useState<any>({})
  const hasInitialFormData = useRef<boolean>(false) // Using a ref to avoid re-renders
  const updatedFormData = useRef<any>(null)

  const { data, loading, refetch } = useQuery(GET_FORM, {
    variables: {
      id: formId,
    },
    fetchPolicy: "no-cache",
  })

  const [updateSubmitted, { error }] = useMutation(UPDATE_SUBMITTED)

  const form: types.Submitted = useMemo(() => {
    return get(data, "submitted")
  }, [data])

  useEffect(() => {
    if (!form) return

    const formTemplate: FormTemplate = form.sourceId || 1
    const { schema, uiSchema } = getSchemaResponse(
      formTemplate,
      authContextState.isROAdmin,
    )

    setSchema(schema)
    setUISchema(uiSchema)
    changeLabels(schema.properties)

    setFormData(form.userInput)
  }, [form, authContextState.isROAdmin])

  function handleChange(e: IChangeEvent<any>, es?: ErrorSchema) {
    if (hasInitialFormData.current) {
      updatedFormData.current = e.formData
    } else {
      hasInitialFormData.current = true
    }
  }

  async function checkPendingUpdates() {
    const lastUpdatedAt = form.updatedAt
    const { data: newData, error } = await refetch()
    if (error) return
    const newUpdatedAt = newData.submitted.updatedAt
    if (newUpdatedAt > lastUpdatedAt && newUpdatedAt > skipUpdatePrompt) {
      changePendingUpdates(newData.submitted)
      return //don't save if there are pending updates
    }
  }

  function handleSubmit(e: ISubmitEvent<any>) {
    const submit = async () => {
      if (authContextState.isROAdmin) return

      await checkPendingUpdates()
      const variables = {
        id: form.id,
        input: {
          firmId: form.firmId,
          userInput: e.formData,
        },
      }

      await updateSubmitted({
        variables,
      })

      if (error) {
        reportErrorWithFormData(error, variables)
        toast(<Error body={returnHumanReadableError(error)} />)
        console.error("error posting to database", error)
      }

      toast(
        <Success
          body={
            "Thank you for submitting your profile, your information is very important to us and your cooperation is greatly appreciated."
          }
        />,
      )

      history.push("/")
    }
    submit()
  }

  if (!formId) {
    history.goBack()
  }

  return (
    <div className='asset-form'>
      <ImportUpdates
        changePendingUpdates={changePendingUpdates}
        changeSkipUpdatePrompt={changeSkipUpdatePrompt}
        setFormData={setFormData}
        pendingUpdates={pendingUpdates}
        labels={labels}
        formData={formData}
      />
      <Paper>
        <Container>
          <Form
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            form={form}
            uiSchema={uiSchema}
            formData={formData}
            schema={schema}
            formId={formId}
          />
        </Container>
      </Paper>
    </div>
  )
}

export default AssetForm
