import { Modal, Paper } from "@material-ui/core"
import React, { useState } from "react"
import { Button } from "../Button"

interface Props {
  open: boolean
  onCancel(): void
  onConfirm(): Promise<void>
  name: string
}
export const ConfirmModal = ({ open, onCancel, onConfirm, name }: Props) => {
  const [deleting, setDeleting] = useState(false)

  function handleCancel(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    event.stopPropagation()
    onCancel()
  }

  async function handleConfirmDelete(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    event.stopPropagation()
    setDeleting(true)
    await onConfirm()
    setDeleting(false)
  }
  return (
    <Modal
      open={open}
      aria-labelledby='delete-title'
      aria-describedby='delete-title'
      onBackdropClick={handleCancel}
    >
      <Paper className='modal'>
        <p id='delete-title'>Are you sure you want to delete {name}?</p>
        <div className='footer'>
          <Button
            disabled={deleting}
            variant='contained'
            color='primary'
            onClick={handleCancel}
          >
            No, Cancel Delete
          </Button>
          <Button
            disabled={deleting}
            variant='contained'
            color='secondary'
            onClick={handleConfirmDelete}
          >
            Yes, Delete {name}
          </Button>
        </div>
      </Paper>
    </Modal>
  )
}
