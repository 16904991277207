import { useMutation, useQuery } from "@apollo/client"
import { Container, Typography } from "@material-ui/core"
import { ErrorSchema, IChangeEvent, ISubmitEvent, UiSchema } from "@rjsf/core"
import * as Sentry from "@sentry/react"
import * as types from "graphql-types/generated/portal-client-types"
import { JSONSchema7 } from "json-schema"
import { get } from "lodash"
import { parse } from "query-string"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { Redirect, useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { AuthContext } from "../../context/AuthContext"
import { MainContext } from "../../context/MainContext"
import { Error, Success } from "../../Toast"
import { getSchemaResponse } from "../../utils/buildSchema"
import { returnHumanReadableError } from "../../utils/errorHandler"
import FormTemplate from "../../utils/FormTemplate"
import Paper from "../Paper"
import Spinner from "../Spinner"
import ProfileContent from "./Form"
import { CREATE_FIRM, GET_FIRM, UPDATE_FIRM } from "./graphql"

function firmName(input: any) {
  // assume first value is name
  const key = Object.keys(input)
    .map((k) => Number(k))
    .sort((a, b) => a - b)[0]
  return input[key]
}

export const FirmProfile = () => {
  const [schema, setSchema] = useState<JSONSchema7 | any>(null)
  const [uiSchema, setUISchema] = useState<UiSchema>({})
  const [formData, setFormData] = useState<any>(null)
  const [submitting, setSubmitting] = useState(false)
  const { firmId } = useParams<{ firmId: string }>()
  const [authContextState] = useContext(AuthContext)
  const { currentUser } = useContext(MainContext)
  const hasInitialFormData = useRef<boolean>(false)
  const updatedFormData = useRef<any>(null)
  const { isAdmin, isROAdmin } = authContextState
  const history = useHistory()
  const [updateFirm, { error: updateFirmError }] = useMutation(UPDATE_FIRM)
  const [createFirm, { error: createFirmError }] = useMutation(CREATE_FIRM)

  const { data, loading } = useQuery(GET_FIRM, {
    variables: {
      firmId,
    },
  })
  const firm: types.Firm = useMemo(() => {
    return get(data, "firm") ?? { id: firmId }
  }, [data, loading])

  const query = parse(window.location.search)
  const firmType = query["firmType"] ?? firm?.firmType?.name
  const organizationId = query["organizationId"] ?? currentUser?.organizationId
  const [isNew, setIsNew] = useState(query["new"] === "true")

  const formTemplateId = useMemo(() => {
    if (firmType === "Company") return FormTemplate.Company
    if (firmType === "Asset Manager") return FormTemplate.Firm

    // During Cypress tests, the generated RCG test user does not have a firm type, so it will
    // come back null. It should not be null in any other situation.
    if (firm?.firmTypeId === null) return FormTemplate.Firm

    // data still loading, so trigger empty screen until data loaded
    return null
  }, [firmType, firm])

  useEffect(() => {
    if (formTemplateId === null) {
      return
    }

    const schemaResponse = getSchemaResponse(formTemplateId, isROAdmin)
    setSchema(schemaResponse.schema)
    setUISchema(schemaResponse.uiSchema)

    if (firm && firm.profileInput) {
      setFormData(firm.profileInput)
    }
  }, [formTemplateId, firm])

  async function saveFirm(profileInput: any) {
    if (isNew) {
      setIsNew(false)
      await createFirm({
        variables: {
          id: firmId,
          input: {
            organizationId,
            name: firmName(profileInput),
            profileInput,
            firmType: {
              name: firmType,
            },
          },
        },
      })
    } else {
      await updateFirm({
        variables: {
          id: firmId,
          input: {
            profileInput,
          },
        },
      })
    }
  }

  function handleChange(e: IChangeEvent<any>, es?: ErrorSchema) {
    if (hasInitialFormData.current) {
      updatedFormData.current = e.formData
    } else {
      hasInitialFormData.current = true
    }
  }

  async function handleSubmit(e: ISubmitEvent<any>) {
    const { formData } = e
    if (isNew && !firmName(formData)) {
      /************************************
       *
       *  TODO HIGHLIGHT REQUIRED FIELD ROC-49
       */
      return
    }
    setSubmitting(true)
    await saveFirm(formData)
    toast(
      <Success
        body={
          "Thank you for submitting your profile, your information is very important to us and your cooperation is greatly appreciated."
        }
      />,
    )
    history.push("/")
  }

  if (updateFirmError) {
    toast(<Error body={returnHumanReadableError(updateFirmError)} />)
    Sentry.withScope((scope) => {
      scope.addBreadcrumb({
        type: "debug",
        category: "updatedFormData",
        data: updatedFormData.current,
      })
      scope.addBreadcrumb({
        type: "debug",
        category: "formData",
        data: formData,
      })
      Sentry.captureException(updateFirmError)
    })
  }

  if (!firmId) {
    return <Redirect to='/' />
  }

  if (
    !organizationId ||
    (!isAdmin && !isROAdmin && currentUser?.organizationId !== organizationId)
  ) {
    return <Error body='Invalid organization' />
  }

  if (submitting || formTemplateId === null) {
    return <Spinner />
  }

  return (
    <div className='firm-profile'>
      <Typography variant='h4' component='h2' gutterBottom>
        {isNew ? "New Firm" : get(firm, "name", "Firm")}
      </Typography>
      <Paper>
        <Container>
          <ProfileContent
            schema={schema}
            uiSchema={uiSchema}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            formData={formData}
            formTemplateId={formTemplateId}
            firm={firm}
            loading={loading}
          />
        </Container>
      </Paper>
    </div>
  )
}

export default FirmProfile
