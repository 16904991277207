import TextField, {
  StandardTextFieldProps as TextFieldProps,
} from "@material-ui/core/TextField"
import { FieldProps, utils } from "@rjsf/core"
import React from "react"

const { getDisplayLabel } = utils

export default function NumberField(props: FieldProps & TextFieldProps) {
  const {
    required,
    readonly,
    disabled,
    label,
    onChange,
    onBlur,
    onFocus,
    autofocus,
    schema,
    uiSchema,
    rawErrors = [],
    idSchema,
    name,
    formData,
  } = props
  const id = idSchema.$id

  const _onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value.trim() === "" || value.slice(-1) === ".") {
      return onChange(value)
    }
    const parsedValue = Number(value)
    if (isNaN(parsedValue)) {
      return onChange(value)
    } else {
      return onChange(parsedValue)
    }
  }

  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, value)
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value)

  const displayLabel = getDisplayLabel(schema, uiSchema)

  return (
    <TextField
      {...props}
      value={formData}
      id={id}
      type='text'
      name={name}
      label={displayLabel ? label || schema.title : false}
      autoFocus={autofocus}
      required={required}
      disabled={disabled || readonly}
      error={rawErrors.length > 0}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}
