import { AppBar, Button, Toolbar } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React, { useContext, useState } from "react"
import { Link, Redirect } from "react-router-dom"
import logo from "../../assets/images/theodc.png"
import { AuthContext } from "../../context/AuthContext"

const navStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 500,
    position: "relative",
  },
  logo: {
    pointerEvents: "none",
  },
  bar: {
    marginBottom: "1em",
    backgroundColor: "hsla(211deg,84%,32%,1)",
    "& button": {
      padding: "0.4em 0.8em",
      fontSize: "1rem",
      textTransform: "capitalize",
      fontWeight: "500",
      color: "hsl(0deg,0%,85%)",
      whiteSpace: "nowrap",
    },
    "& button:hover": {
      color: "hsl(0deg,0%,100%)",
      backgroundColor: "hsla(211deg,84%,28%,1)",
    },
  },
}))

export const AdminNavbar = () => {
  const [{ email }, { signOut }] = useContext(AuthContext)
  const [logOut, setLogOut] = useState(false)
  const styles = navStyles()

  const handleSignOut = () => {
    signOut()
    setLogOut(true)
  }

  return (
    <>
      <AppBar position='static' className={styles.bar}>
        <Toolbar>
          <span className={styles.title}>
            <img src={logo} className={styles.logo} alt='logo' />
          </span>
          <Link to='/'>
            <Button data-cy='nav-admin-dashboard'>Admin Dashboard</Button>
          </Link>
          <Button data-cy='nav-sign-out' onClick={handleSignOut}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>
      {logOut && <Redirect to='/login' />}
    </>
  )
}

export default AdminNavbar
