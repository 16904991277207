import Form from "@rjsf/material-ui"
import { JSONSchema7Object } from "json-schema"
import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { default as rawSchema } from "../../data/enter-password-schema.json"
import { default as ui } from "../../data/enter-password-ui-schema.json"
import { Button } from "../index"
import { Error } from "../../Toast"
import { AuthContext } from "../../context/AuthContext"
import ErrorListTemplate from "../form/ErrorListTemplate"
import SimpleNavbar from "../Navbar/SimpleNavbar"
import { toast } from "react-toastify"

export const PasswordForm = () => {
  const history = useHistory()
  const [authState, { answerPasswordChallenge }] = useContext(AuthContext)
  const schema = rawSchema as JSONSchema7Object
  const uiSchema = ui as JSONSchema7Object

  const handleSubmit = async (event: any): Promise<void> => {
    try {
      const { email, password } = event.formData
      await answerPasswordChallenge(email, password)
    } catch (error) {
      toast(<Error body={error.message} />)
      console.error(error)
    }
  }

  useEffect(() => {
    if (authState.isAuthenticated && authState.isMachineUser) {
      history.push("/")
    }
  }, [authState.isAuthenticated, authState.isMachineUser, history])

  return (
    <div className='password-challenge'>
      <SimpleNavbar />

      <Form
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={handleSubmit}
        ErrorList={ErrorListTemplate}
      >
        <Button variant='contained' color='primary' type='submit'>
          Confirm
        </Button>
      </Form>
    </div>
  )
}

export default PasswordForm
