import { Box } from "@material-ui/core"
import { FieldProps } from "@rjsf/core"
import { cloneDeep, isEmpty, isNaN, range, round, set } from "lodash"
import React, { useCallback, useMemo } from "react"
import ReactDataSheet from "react-datasheet"
import "react-datasheet/lib/react-datasheet.css"
import Button from "../../components/Button"
import DescriptionField from "../DescriptionField"
import TitleField from "../TitleField"
import { ColumnDefinition, performanceColumns } from "./ColumnDefinitions"
import { getCellValue } from "./GridUtils"

interface Row {
  year: number
  jan?: number | null
  feb?: number | null
  mar?: number | null
  apr?: number | null
  may?: number | null
  jun?: number | null
  jul?: number | null
  aug?: number | null
  sep?: number | null
  oct?: number | null
  nov?: number | null
  dec?: number | null
  ytd?: number
}

const blankYear = (year: number): Row => ({
  year,
  jan: undefined,
  feb: undefined,
  mar: undefined,
  apr: undefined,
  may: undefined,
  jun: undefined,
  jul: undefined,
  aug: undefined,
  sep: undefined,
  oct: undefined,
  nov: undefined,
  dec: undefined,
  ytd: undefined,
})

interface GridRow {
  key: string
  value?: string | number | null
  readOnly?: boolean
}
type Grid = GridRow[][]

const currentYear = new Date().getFullYear()
const defaultRowsData: any[] = [blankYear(currentYear)]

function calculateYtd(row: Row) {
  let ytd = 1
  Object.keys(row).forEach((key) => {
    const value = row?.[key as keyof Row]
    if (key !== "ytd" && key !== "year") {
      const float =
        typeof value === "string" && value ? parseFloat(value) : value
      if (float && !isNaN(float)) {
        ytd *= 1 + float / 100.0
      }
    }
  })
  return round((ytd - 1) * 100, 2)
}

function sortRows(row1: Row, row2: Row): number {
  if (row1.year > row2.year) return -1
  if (row1.year < row2.year) return 1
  return 0
}

function generateGridFromRows(rows: Row[], columns: ColumnDefinition[]) {
  let grid: Grid = []
  rows.forEach((row, rowIx) => {
    grid[rowIx] = []
    columns.forEach((col, colIx) => {
      const key = col.propName
      let value = row?.[key as keyof Row]
      if (key === "ytd") {
        value = calculateYtd(row)
      } else if (key !== "year") {
        value = value === null ? null : Number(value)
      }
      grid[rowIx][colIx] = {
        key,
        value,
        readOnly: col.readOnly,
      }
    })
  })

  return grid
}

function prependRowsThroughCurrentYear(rows: Row[]) {
  const lastEntry = rows.sort(sortRows)[0]

  if (lastEntry.year !== currentYear) {
    const remainingYears = range(lastEntry.year + 1, currentYear + 1)
    const emptyRows = remainingYears.reduce((accu: Row[], year: number) => {
      return accu.concat([blankYear(year)])
    }, [])
    return emptyRows.concat(rows)
  }
  return rows
}

export const PerformanceGrid = (props: FieldProps) => {
  const { schema, formData, onChange } = props

  const columns = performanceColumns

  const rawFormData = useMemo(() => {
    let rawFormData = formData
    if (!formData || formData.length < 1 || isEmpty(formData[0])) {
      rawFormData = defaultRowsData
    }
    return prependRowsThroughCurrentYear(rawFormData)
  }, [formData])

  const grid = useMemo(() => {
    const sortedRows = rawFormData.sort(sortRows)
    const grid = generateGridFromRows(sortedRows, columns)
    return grid
  }, [rawFormData])

  const handleRowsChange = useCallback(
    (changes: any) => {
      let nextFormData = cloneDeep(rawFormData)
      let tmpGrid: any[][] = cloneDeep(grid || [])

      changes.forEach(({ cell, row, col, value }: any) => {
        const cellValue = getCellValue(value, columns[col])
        set(nextFormData[row], cell.key, cellValue)
        tmpGrid[row][col] = cell
        tmpGrid[row][col].value = cellValue
      })

      onChange(nextFormData)
    },
    [rawFormData, columns, grid, onChange],
  )

  // Add new year's worth of data rows
  const addYear = useCallback(() => {
    const rows = rawFormData

    const lastYear = rows[rows.length - 1].year
    const newYear = lastYear - 1

    const nextRows = [...rows, blankYear(newYear)]

    onChange(nextRows)
  }, [rawFormData, onChange])

  if (!grid) {
    return null
  }

  function renderCellValue(cell: any, rowIx: number, colIx: number) {
    const renderer = columns[colIx].valueRenderer
    return renderer ? renderer(cell, rowIx, colIx) : cell.value
  }

  return (
    <div className='performance-grid'>
      {schema.title && <TitleField title={schema.title} />}
      {schema.description && (
        <DescriptionField description={schema.description} />
      )}
      <Box style={{ marginTop: "1em" }}>
        <ReactDataSheet
          data={grid}
          // https://github.com/nadbm/react-datasheet/issues/309
          parsePaste={(pastedString) =>
            pastedString
              .trim()
              .split(/\r?\n/)
              .map((row) => row.split("\t"))
          }
          valueRenderer={renderCellValue}
          onCellsChanged={handleRowsChange}
          sheetRenderer={(props: any) => (
            <table className={props.className}>
              <thead>
                <tr>
                  {columns.map((col, ix) => (
                    <th key={ix} className='cell read-only'>
                      <div>
                        <span>{col.title}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{props.children}</tbody>
            </table>
          )}
        />
        <Button variant='contained' color='primary' onClick={addYear}>
          Add Year
        </Button>
      </Box>
    </div>
  )
}

export default PerformanceGrid
