import React, { useMemo, useCallback } from "react"
import { FieldProps } from "@rjsf/core"
import DescriptionIcon from "@material-ui/icons/Description"
import DeleteIcon from "@material-ui/icons/Delete"
import { makeStyles } from "@material-ui/core/styles"
import { useMutation, gql, useQuery } from "@apollo/client"
import { File } from "../../generated/types"
import { FormLabel, IconButton, CircularProgress } from "@material-ui/core"
import { ImpactFormContext } from "../../components/form/ImpactForm"
import { ok } from "../utils"

export const SINGLE_UPLOAD = gql`
  mutation SingleFileUpload($file: Upload!, $input: FileUploadInput!) {
    singleFileUpload(file: $file, input: $input) {
      id
      name
    }
  }
`

export const DELETE_FILE = gql`
  mutation DeleteFile($fileId: String!) {
    deleteFile(fileId: $fileId)
  }
`

export const DOWNLOAD_FILE = gql`
  query FileDownload($fileId: String!) {
    file(fileId: $fileId) {
      id
      name
      signedUrl
    }
  }
`

interface IFileInfo {
  id: string
  name: string
}

interface ILegacyFileInfo {
  file_id: string
  file_name: string
}

function isLegacyFileInfoType(fileInfo: any): fileInfo is ILegacyFileInfo {
  return fileInfo.hasOwnProperty("file_id")
}

function normalizeFileInfo(fileInfo: IFileInfo | ILegacyFileInfo): IFileInfo {
  if (isLegacyFileInfoType(fileInfo)) {
    return {
      id: fileInfo.file_id,
      name: fileInfo.file_name,
    }
  }

  return fileInfo
}

const useStyles = makeStyles(() => ({
  viewFile: {
    display: "flex",
    alignItems: "center",
  },
}))

function CustomFileField({
  schema,
  uiSchema,
  required,
  formData,
  onChange,
  formContext,
  readonly,
  disabled,
  autofocus,
  id,
  name: questionId,
}: FieldProps) {
  const classes = useStyles()
  const [uploadFile, { error, loading }] = useMutation(SINGLE_UPLOAD)
  const [deleteFile] = useMutation(DELETE_FILE)
  const { submittedId, formTemplateId } = formContext as ImpactFormContext
  const uiOptions = uiSchema["ui:options"] || {}

  const fileInfo: IFileInfo | undefined | null = useMemo(() => {
    if (!formData || !Object.keys(formData).length) return

    return normalizeFileInfo(formData)
  }, [formData])

  const { data: downloadedFile } = useQuery<{ file: File }>(DOWNLOAD_FILE, {
    variables: { fileId: fileInfo?.id },
    skip: !fileInfo?.id,
  })

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      !event.target.files ||
      !event.target.validity.valid ||
      event.target.files.length < 1
    ) {
      return
    }
    if (!ok(submittedId) || !ok(formTemplateId)) return
    const file = event.target.files[0]
    if (!file || !file.size) return

    uploadFile({
      variables: {
        file,
        input: {
          submittedId,
          formTemplateId,
          questionId,
        },
      },
    })
      .then((result) => {
        const nextFormData = result.data.singleFileUpload
        onChange(nextFormData)
      })
      .catch((e) => {
        console.error("File upload error", error)
      })
  }

  const handleDelete = useCallback(
    (fileInfo: any) => {
      const normalizedFileInfo = normalizeFileInfo(fileInfo)
      // Market file as deleted in metadata store
      deleteFile({ variables: { fileId: normalizedFileInfo.id } })

      onChange(undefined)
    },
    [deleteFile, onChange],
  )

  return (
    <div className='file-widget'>
      <FormLabel required={required} htmlFor={questionId}>
        <span dangerouslySetInnerHTML={{ __html: schema.title ?? "" }} />
      </FormLabel>
      <p>
        <input
          id={questionId}
          type='file'
          onChange={handleChange}
          disabled={readonly || disabled}
          defaultValue=''
          autoFocus={autofocus}
          accept={uiOptions?.accept as string}
        />
      </p>
      {loading && <CircularProgress />}
      {fileInfo && (
        <div key={fileInfo.id} className={classes.viewFile}>
          <a
            download
            data-cy='uploaded-file-name'
            className='fileDownload_button'
            href={downloadedFile?.file?.signedUrl ?? ""}
          >
            <DescriptionIcon
              fontSize='large'
              {...(!downloadedFile?.file?.signedUrl && { color: "disabled" })}
            />
            {fileInfo.name}
          </a>
          <IconButton
            aria-label='delete'
            title={`Delete ${fileInfo.name}`}
            onClick={handleDelete.bind(null, fileInfo)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default CustomFileField
