import { Card, CardContent } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Form from "@rjsf/material-ui"
import { JSONSchema7Object } from "json-schema"
import React, { useContext } from "react"
import { Redirect, useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { AuthContext } from "../../context/AuthContext"
import { default as rawSchema } from "../../data/login-schema.json"
import { default as ui } from "../../data/ui-schema.json"
import { Error } from "../../Toast"
import ErrorListTemplate from "../form/ErrorListTemplate"
import { Button, Link } from "../index"
import SimpleNavbar from "../Navbar/SimpleNavbar"

const useStyles = makeStyles(() => ({
  introPFirst: { marginTop: 0 },
  introPLast: { marginBottom: 0 },
}))

export const Login = () => {
  const classes = useStyles()
  const history = useHistory()
  const [authState, { signIn }] = useContext(AuthContext)
  const schema = rawSchema as JSONSchema7Object
  const uiSchema = ui as JSONSchema7Object

  const handleSubmit = async (event: any): Promise<void> => {
    const { email } = event.formData
    try {
      if (email === (window as any).TEST_USER) {
        history.push("/enter-password")
      } else {
        await signIn(email)
        history.push("/enter-code")
      }
    } catch (error) {
      toast(<Error body={error.message} />)
      console.error(error)
    }
  }

  if (authState.isAuthenticated) {
    return <Redirect to='/' />
  }

  return (
    <div className='login'>
      <SimpleNavbar />
      <Card>
        <CardContent>
          <p className={classes.introPFirst}>
            Welcome to our data submission portal! We invite investors and
            companies across the globe to contribute information on their
            organizations and the investment opportunities they represent. Your
            participation will not only allow RockCreek to continue to provide
            best-in-class asset management and advisory services to new and
            existing clients, but your data will be added to our market-leading
            impact investment intelligence platform: The Objective Data
            Collective.
          </p>
          <p>
            With the largest diversity, equity, and inclusion (DEI) database in
            the asset management industry, longitudinal financial performance
            across all asset classes, and thousands of
            ESG/sustainability-focused investments, investors can discover,
            research, and connect with the investments that matter most to them.
          </p>
          <p className={classes.introPLast}>
            Portal Instructions and FAQs:{" "}
            <a href={`${process.env.PUBLIC_URL}/documents/instructions.pdf`}>
              Click here
            </a>
          </p>
          <p className={classes.introPLast}>
            General questions or need assistance in completing the
            questionnaire?{" "}
            <a href='mailto:portal@therockcreekgroup.com'>Click here</a>.
          </p>
        </CardContent>
      </Card>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={handleSubmit}
        ErrorList={ErrorListTemplate}
      >
        <Button variant='contained' color='primary' type='submit'>
          Login
        </Button>
        <Link color='primary' href='/signup'>
          New user? Sign up
        </Link>
      </Form>
    </div>
  )
}

export default Login
