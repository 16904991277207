import { useQuery } from "@apollo/client"
import { Typography } from "@material-ui/core"
import * as types from "graphql-types/generated/portal-client-types"
import { get } from "lodash"
import React, { useContext, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { MainContext } from "../../context/MainContext"
import { Error } from "../../Toast"
import Paper from "../Paper"
import Spinner from "../Spinner"
import { CURRENT_USER } from "./graphql"
import SubmissionRows from "./SubmissionRows"
import { MultiFirmTable, SingleFirmTable } from "./Table"

function UserForms() {
  const history = useHistory()
  const { currentUser: userFromContext } = useContext(MainContext)
  const { data: currentUserData, loading: currentUserLoading } = useQuery<{
    currentUser: types.User
  }>(CURRENT_USER, {
    fetchPolicy: "cache-and-network",
    variables: {
      page: { limit: 100, offset: 0 },
      sort: [{ field: "sourceId", order: "ASC" }],
    },
  })

  const currentUser = useMemo(() => {
    return get(currentUserData, "currentUser")
  }, [currentUserData, currentUserLoading, userFromContext])

  const allowMultiFirm: boolean = get(
    currentUser,
    "organization.allowMultiFirm",
  )

  function handleClick(_event: React.MouseEvent, form: types.Submitted) {
    history.push(`/form/${form.id}`)
  }

  if (currentUserLoading) {
    return (
      <div className='user-forms'>
        Review or update information regarding the firm you represent by
        clicking the firm profile below.
        <Paper>
          <Spinner />
        </Paper>
      </div>
    )
  }

  if (!currentUser || !currentUser?.organization) {
    toast(<Error body='Something went wrong' />)
    history.push("/")
    return null
  }

  const { organization } = currentUser

  return (
    <div className='user-forms' data-cy='user-forms'>
      <Typography variant='h4' component='h4' gutterBottom>
        Firms
      </Typography>
      Review or update information regarding the firm you represent by clicking
      the firm profile below.{" "}
      <Paper>
        {organization.allowMultiFirm ||
        organization.serviceProviderFirms.length > 0 ? (
          <MultiFirmTable organization={organization} />
        ) : (
          <SingleFirmTable organization={organization} />
        )}
      </Paper>
      <Typography variant='h4' component='h2' gutterBottom>
        Investment Strategies
      </Typography>
      Please click on an existing fund profile below to review or update the
      product's information.
      <SubmissionRows
        isPrimaryFirm
        allowMultiFirm={allowMultiFirm}
        firm={organization.primaryFirm}
        handleClick={handleClick}
        noSubmissionsMessage='No Investment Strategies are linked to this firm, click Add Investment to get started'
      />
      {organization.serviceProviderFirms.length > 0 &&
        organization.serviceProviderFirms.map((providerFirm) => (
          <SubmissionRows
            allowMultiFirm={allowMultiFirm}
            firm={providerFirm}
            handleClick={handleClick}
            noSubmissionsMessage='No Investment Strategies are linked to this firm, click Add Investment to get started'
          />
        ))}
    </div>
  )
}

export default UserForms
