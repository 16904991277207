import React, { useMemo } from "react"
import { useQuery } from "@apollo/client"
import Spinner from "../Spinner"
import { get } from "lodash"
import ImpactForm from "../form/ImpactForm"
import { UiSchema, IChangeEvent, ISubmitEvent, ErrorSchema } from "@rjsf/core"
import NoOrganizationMessage from "./NoOrganizationMessage"
import { JSONSchema7 } from "json-schema"
import * as types from "graphql-types/generated/portal-client-types"
import { CURRENT_USER } from "./graphql"
import { makeStyles } from "@material-ui/core/styles"
import FormTemplate from "../../utils/FormTemplate"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  spinner: {},
}))

interface FormProps {
  schema: JSONSchema7
  uiSchema: UiSchema
  handleChange: (e: IChangeEvent<any>, es?: ErrorSchema) => any
  handleSubmit: (e: ISubmitEvent<any>) => any
  formData: any
  formTemplateId: number
  firm: types.Firm
  loading: boolean
}

function Form({
  schema,
  uiSchema,
  handleChange,
  handleSubmit,
  formData,
  formTemplateId,
  firm,
  loading,
}: FormProps) {
  const { data: currentUserData, loading: currentUserLoading } = useQuery(
    CURRENT_USER,
  )
  const user: types.User = useMemo(() => {
    return get(currentUserData, "currentUser")
  }, [currentUserData])

  const classes = useStyles()

  if (user && !user.organizationId) {
    return <NoOrganizationMessage email={user.email} />
  }
  if (loading || (!schema && !formData))
    return <Spinner className={classes.spinner} />

  return (
    <ImpactForm
      schema={schema}
      uiSchema={uiSchema}
      onChange={handleChange}
      onSubmit={handleSubmit}
      formData={formData}
      idPrefix={`${FormTemplate.Firm}`}
      submittedId={firm.id}
      formTemplateId={formTemplateId}
    />
  )
}

export default Form
