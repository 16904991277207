import { Checkbox } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import FormLabel from "@material-ui/core/FormLabel"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { FieldProps } from "@rjsf/core"
import { JSONSchema7 } from "json-schema"
import { camelCase, startCase } from "lodash"
import React, { useCallback, useContext, useMemo } from "react"
import { AuthContext } from "../context/AuthContext"
import { toHash } from "../utils/toHash"

interface TagLibrary {
  [category: string]: Tag[]
}

interface Tag {
  name: string
  value: string
}

function enumToTags(tagValues: string[]) {
  let tagLibrary: TagLibrary = {}

  tagValues.forEach((tagValue) => {
    const [category, name] = tagValue.split(" - ")
    const tag: Tag = { name, value: tagValue }

    const formattedCategory = camelCase(category)
    if (tagLibrary.hasOwnProperty(formattedCategory)) {
      tagLibrary[formattedCategory].push(tag)
    } else {
      tagLibrary[formattedCategory] = [tag]
    }
  })

  return tagLibrary
}

const defaultData: string[] = []

function isStringArray(data: any): data is string[] {
  return Array.isArray(data) && data.every((d: any) => typeof d === "string")
}

const ImpactTags = (props: FieldProps) => {
  const { schema, formData, onChange, required } = props
  const { title, items } = schema

  const [authContextState] = useContext(AuthContext)

  const tagsData = useMemo(() => {
    if (formData && isStringArray(formData)) {
      return formData
    }
    return defaultData
  }, [formData])

  const tagLibrary = enumToTags((items as JSONSchema7).enum as string[])
  const categories = Object.keys(tagLibrary)

  const handleChange = useCallback(
    ({ target: { checked, value } }) => {
      if (checked) {
        onChange([...tagsData, value])
      } else {
        const newTags = tagsData.filter((tagValue) => tagValue !== value)
        onChange(newTags)
      }
    },
    [onChange, tagsData],
  )

  const checkedTags = toHash(tagsData)

  return (
    <div>
      <FormLabel data-cy='impact-tags' required={required}>
        {title}
      </FormLabel>
      <Grid container justify='flex-start' style={{ marginTop: "25px" }}>
        {categories.map((category) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={category}
            style={{ flexDirection: "column" }}
            data-cy={`impact-tag-category-${camelCase(category)}`}
          >
            <FormLabel component='legend'>
              <Typography variant='button' component='h6'>
                {startCase(category)}
              </Typography>
            </FormLabel>
            <FormGroup style={{ marginBottom: "5px", marginTop: "5px" }}>
              {tagLibrary[category].map(({ name, value }) => (
                <FormControlLabel
                  disabled={authContextState.isROAdmin}
                  key={camelCase(value)}
                  data-cy={`impact-tag-${camelCase(value)}`}
                  control={
                    <Checkbox
                      name={name}
                      onChange={handleChange}
                      checked={!!checkedTags[value]}
                      value={value}
                    />
                  }
                  label={name}
                />
              ))}
            </FormGroup>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default ImpactTags
